"use client";

import { useEffect } from "react";
import ErrorPage from "rivals/components/pages/error/ErrorPage";
import { RESPONSE_PAGE_NOT_FOUND_ERROR } from "rivals/services/base";
import logger from "rivals/shared/utils/logger";

const PAGE_NAME = "not-found";

// TODO: https://griosf.atlassian.net/browse/RVLS-8613
export default function Error({
  error
}: {
  error: Error & { digest?: string };
}): React.JSX.Element {
  useEffect(() => {
    logger({
      message: error,
      page: PAGE_NAME,
      severity: "error",
      type: "error"
    });
  }, [error]);

  logger({
    message: "hit",
    page: PAGE_NAME,
    severity: "error",
    type: "error"
  });

  return (
    <div className="root">
      <ErrorPage statusCode={RESPONSE_PAGE_NOT_FOUND_ERROR} userError />
    </div>
  );
}
